import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FaEnvelope, FaGithub, FaTwitter } from "react-icons/fa"
import SEO from "./seo"

const Layout = ({ location, title, children, description }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          author
          social {
            twitter
            github
          }
        }
      }
    }
  `);

  let header
  if (isRootPath) {
    header = (
      <h1 className="main-heading text-center">
        <Link to="/">{data.site.siteMetadata.title}</Link>
      </h1>
    )
  } else {
    header = (
      <div className="navbar">
        <Link className="navbar-brand" to="/">{data.site.siteMetadata.title}</Link>
        <div className="navbar-items">
          <Link className="navbar-item" to="/projects">Projects</Link>
          <Link className="navbar-item" to="/publications">Publications</Link>
          <a className="navbar-icon" href={`https://github.com/${data.site.siteMetadata.social.github}`}><FaGithub/></a>
          <a className="navbar-icon" href={`https://twitter.com/${data.site.siteMetadata.social.twitter}`}><FaTwitter/></a>
          <a className="navbar-icon" href={`mailto:marten@martensigwart.com`}><FaEnvelope/></a>
        </div>
      </div>
    )
  }

  return (
    <>
      <header className="global-header" data-is-root-path={isRootPath}>{header}</header>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <SEO title={title} description={description}/>
        <main>{children}</main>
        <footer className="text-center">
          © {new Date().getFullYear()} {data.site.siteMetadata.author}
        </footer>
      </div>
    </>
  )
}


export default Layout
